import React from 'react';
import styled, { css } from 'styled-components';
import { useTranslation } from 'react-i18next';

import { AppLink } from 'components/Link';
import { Icon } from './Icon';
import { useAppSelector } from 'redux/hooks';
import { LangSwitch } from './LangSwitch';
import { trackLinkClick } from 'hooks/useGA';

interface MobileNavProps {
    languages: string[];
    hasInfo: boolean;
    testmode: boolean;
}

export const MobileNav: React.FC<MobileNavProps> = (props) => {
    const { languages, hasInfo, testmode } = props;
    const { t } = useTranslation();

    const { iframe } = useAppSelector((state) => {
        return {
            iframe: state.store.iframe,
        };
    });

    if (iframe) return null;

    return (
        <StyledMobileNav preview={!!testmode}>
            <StyledIcon>
                <AppLink to="/">
                    <Icon name="esq" alt="EventSquare" />
                </AppLink>
            </StyledIcon>
            <StyledNavList>
                {hasInfo && (
                    <StyledNavItem>
                        <AppLink
                            to="/info"
                            className={({ isActive }) => (isActive ? 'active' : 'none')}
                            onClick={() => trackLinkClick('info', 'mobile_nav')}
                        >
                            {t('mobile_nav.info')}
                        </AppLink>
                    </StyledNavItem>
                )}
                <StyledNavItem>
                    <AppLink
                        to="/support"
                        className={({ isActive }) => (isActive ? 'active' : 'none')}
                        onClick={() => trackLinkClick('help', 'mobile_nav')}
                    >
                        {t('mobile_nav.support')}
                    </AppLink>
                </StyledNavItem>
            </StyledNavList>

            <LangSwitch languages={languages} />
        </StyledMobileNav>
    );
};

const StyledMobileNav = styled.nav<{ preview: boolean }>`
    display: none;

    @media (max-width: 57em) {
        background-color: #fff;
        padding: 0.35rem 1.5rem 0.35rem 1.25rem;
        display: flex;
        flex-direction: row;
        align-items: center;

        ${(props) =>
            props.preview &&
            css`
                margin-top: 3rem;
            `}
    }
`;

const StyledIcon = styled.div`
    color: ${(props) => props.theme.colors.fontMedium};
    margin-right: auto;
    font-size: 2.8rem;
    display: flex;
    justify-content: start;
    align-items: center;

    a {
        text-decoration: none;
        color: inherit;
        display: flex;
        justify-content: start;
        align-items: center;
    }
`;

const StyledNavList = styled.ul`
    display: flex;
    align-items: center;
    justify-content: end;
`;

const StyledNavItem = styled.li`
    color: ${(props) => props.theme.colors.fontMedium};

    padding: 0.75rem 0;
    text-align: right;
    font-weight: 600;
    margin-left: 0.5rem;
    width: 6rem;

    a {
        text-decoration: none;
        color: inherit;

        &.active {
            font-weight: 800;
        }
    }
`;
