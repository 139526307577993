import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import axios, { AxiosError, AxiosResponse } from 'axios';
import Cookies from 'universal-cookie';

import { AppDispatch, reduxStore } from 'redux/store';
import { EventSquareEvent } from 'types/EventTypes';
import { EventSquareEdition, ShowDetail } from 'types/EditionTypes';
import { EventSquareCart } from 'types/CartTypes';
import { devLog } from 'lib/dev';
interface StoreState {
    event: undefined | EventSquareEvent;
    eventError: ErrorType;
    edition: undefined | EventSquareEdition;
    editionError: ErrorType;
    cartId: undefined | string;
    cartExpiration: undefined | number;
    iframe: boolean;
    cart: undefined | EventSquareCart;
    cartError: ErrorType;
    cartIsLoading: boolean;
    previewToken: string | undefined;
    password: string | undefined;
    referrer: string | undefined;
    activeShow: ShowDetail | undefined;
}

type ErrorType = AxiosResponse['data'] | undefined;

const initialState: StoreState = {
    event: undefined,
    eventError: undefined,
    edition: undefined,
    editionError: undefined,
    cartId: undefined,
    cartExpiration: undefined,
    iframe: false,
    cart: undefined,
    cartError: undefined,
    cartIsLoading: false,
    previewToken: undefined,
    password: undefined,
    referrer: undefined,
    activeShow: undefined,
};

export const storeSlice = createSlice({
    name: 'storeSlice',
    initialState,
    reducers: {
        setEvent: (state, action: PayloadAction<EventSquareEvent>) => {
            devLog('event', action.payload);
            state.event = action.payload;
            state.edition = undefined;
        },
        setEventError: (state, action: PayloadAction<ErrorType>) => {
            state.eventError = action.payload;
        },
        setEdition: (state, action: PayloadAction<EventSquareEdition | undefined>) => {
            if (action.payload) {
                devLog('edition', action.payload);
                // Save cart details in cookie
                const cookies = new Cookies();
                const cookieObject = {
                    id: action.payload.cart.cartid,
                    edition: action.payload.uri,
                    channel: action.payload.channel.uri,
                    preview_token: state.previewToken,
                    password: state.password,
                };
                const cartExpiresIn = action.payload.cart.expires_in;
                if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
                    // dev code
                    cookies.set('cart', cookieObject, { path: '/', maxAge: cartExpiresIn });
                } else {
                    // production code
                    cookies.set('cart', cookieObject, {
                        path: '/',
                        maxAge: cartExpiresIn,
                        secure: true,
                        sameSite: 'none',
                    });
                }
            }

            state.edition = action.payload;
        },
        setEditionError: (state, action: PayloadAction<ErrorType>) => {
            state.editionError = action.payload;
        },
        setCartId: (state, action: PayloadAction<string | undefined>) => {
            state.cartId = action.payload;
        },
        setCartExpiration: (state, action: PayloadAction<number>) => {
            const now = new Date();
            state.cartExpiration = new Date(now.getTime() + action.payload * 1000).getTime();
        },
        setIframe: (state, action: PayloadAction<boolean>) => {
            state.iframe = action.payload;
        },
        setCart: (state, action: PayloadAction<EventSquareCart | undefined>) => {
            devLog('cart', action.payload);
            state.cart = action.payload;
            state.cartIsLoading = false;
        },
        setCartError: (state, action: PayloadAction<ErrorType>) => {
            state.cartError = action.payload;
        },
        setCartIsLoading: (state, action: PayloadAction<boolean>) => {
            state.cartIsLoading = action.payload;
        },
        setPreviewToken: (state, action: PayloadAction<string | undefined>) => {
            state.previewToken = action.payload;
        },
        setPassword: (state, action: PayloadAction<string | undefined>) => {
            state.password = action.payload;
        },
        setReferrer: (state, action: PayloadAction<string | undefined>) => {
            state.referrer = action.payload;
        },
        setActiveShow: (state, action: PayloadAction<ShowDetail | undefined>) => {
            state.activeShow = action.payload;
        },
    },
});

interface fetchEventParamsInterface {
    language?: string;
    preview_token?: string;
}

export const fetchEvent = (language?: string, previewToken?: string) => {
    return async (dispatch: AppDispatch) => {
        dispatch(setEventError(undefined));
        const params: fetchEventParamsInterface = {};
        const subdomain = window.location.hostname.split('.')[0];

        if (language && language.length == 2) {
            params.language = language;
        }
        if (previewToken) {
            params.preview_token = previewToken;
        }

        try {
            const response = await axios.get(`/api/store/${subdomain}`, { params, timeout: 30000 });
            dispatch(setEvent(response.data.event));
        } catch (error: unknown) {
            if (axios.isAxiosError(error)) {
                console.error(error.response?.data);
                dispatch(setEventError(error.response?.data));
            }
        }
    };
};

interface fetchEditionParamsInterface {
    language: string;
    entry_url: string;
    cart?: string;
    preview_token?: string;
    access_token?: string;
    request_id?: string;
    referer?: string;
    request_url?: string;
    event?: string;
}

interface fetchEditionConfigInterface {
    params: fetchEditionParamsInterface;
    timeout?: number;
    headers?: {
        password?: string;
        authentication?: string;
    };
}

export const fetchEdition = (
    language: string,
    editionUri: string,
    entryUrl: string,
    channelUri?: string,
    previewToken?: string,
    accessToken?: string,
    password?: string,
    requestId?: string,
    auth?: object,
) => {
    return async (dispatch: AppDispatch, getState: typeof reduxStore.getState) => {
        dispatch(setEditionError(undefined));

        const { event, cartId, referrer } = getState().store;
        if (!event) return;

        const params: fetchEditionParamsInterface = {
            language: language,
            entry_url: entryUrl,
        };
        if (cartId) {
            // Pass cart id when available from cookie
            params.cart = cartId;
        } else if (referrer) {
            // If initializing cart and a referrer is available, pass it
            params.referer = referrer;
        }
        if (previewToken) {
            // Pass the preview token
            params.preview_token = previewToken;
        }

        // Append request url and event to params to use for queue token generation
        params.request_url = window.location.href;
        params.event = event.uri;

        // possible fix: && !cartId
        if (accessToken) {
            // Pass the access token
            params.access_token = accessToken;
        }

        if (requestId) {
            params.request_id = requestId;
        }

        const config: fetchEditionConfigInterface = { params: params, timeout: 30000 };

        if (password) {
            config.headers = {
                password: password,
            };
        }

        // Append authentication headers
        if (auth) {
            config.headers = { ...config.headers };
            config.headers.authentication = JSON.stringify(auth);
        }

        try {
            const apiChannel = channelUri ? `/${channelUri}` : '';
            const apiUrl = `/api/store/${event.uri}/${editionUri}${apiChannel}`;
            const response = await axios.get(apiUrl, config);
            // Old queue integration
            if (response.data.edition.queue) {
                window.location.href = response.data.edition.queue.url;
            }

            // if store is available, we don't need a preview token
            if (response.data.edition.channel.store) {
                dispatch(setPreviewToken(undefined));
            } else {
                // else set the preview token, which can be a string or undefined
                dispatch(setPreviewToken(previewToken));
            }

            dispatch(setPassword(password));

            dispatch(setEdition(response.data.edition));
            dispatch(setCartId(response.data.edition.cart.cartid));
            dispatch(setCartExpiration(response.data.edition.cart.expires_in));
        } catch (error: unknown) {
            if (axios.isAxiosError(error)) {
                if (redirectIfQueueIsActive(error.response)) {
                    return;
                }
                dispatch(setEditionError(error.response?.data));
            }
        }
    };
};

interface fetchCartParams {
    language?: string;
}

const redirectIfQueueIsActive = (response: AxiosError['response']) => {
    if (response?.data?.error && response.data.error === 'queue_active') {
        window.location.href = response.data.redirect_url;
        return true;
    }
    return false;
};

export const fetchCart = (language: string | undefined) => {
    return async (dispatch: AppDispatch, getState: typeof reduxStore.getState) => {
        const { cartId } = getState().store;
        dispatch(setCartError(undefined));
        dispatch(setCartIsLoading(true));

        const params: fetchCartParams = {};
        if (language) {
            params.language = language;
        }
        try {
            const apiUrl = `/api/cart/${cartId}`;
            const response = await axios.get(apiUrl, { params, timeout: 30000 });
            dispatch(setCart(response.data.cart));
        } catch (error: unknown) {
            if (axios.isAxiosError(error)) {
                console.error(error.response?.data);

                dispatch(setCartError(error.response?.data));
            }
        }
    };
};

export const {
    setEvent,
    setEventError,
    setEdition,
    setEditionError,
    setCartId,
    setCartExpiration,
    setCart,
    setCartError,
    setCartIsLoading,
    setIframe,
    setPreviewToken,
    setPassword,
    setReferrer,
    setActiveShow,
} = storeSlice.actions;

export default storeSlice.reducer;
